.mainback {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -40px;
}

.page {
  flex: 1;
  width: 100%;
}

.heading {
  font-size: 30px;
}

.hr-margin {
  margin-top: -10px;
}
