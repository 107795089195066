@use "../../SCSS/color-variables";

.body-wrapper {
  display: flex; // This will hold the sidebar and the content area
  justify-content: center;
  width: 100%;
  min-height: 100vb;
  position: relative;
  padding-top: 60px;
  // margin-bottom: -217px;
}

.sidebar-content-container {
  display: flex;
  max-width: 100%;
  width: 100%;
}

.sidebar {
  //flex-grow: 1;
  min-width: 260px;
  max-width: 260px;
  // padding: 20px 15px 240px;
  padding: 3rem 2rem;
  // background: transparent; // Make sure the sidebar background is transparent
  background-image: url("../../Images/tracksBG.png");
  z-index: 2;
  nav {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 10px; // Spacing between links

        a {
          text-decoration: none;
          color: white; // Default link color
          font-size: 12px; // Example font size
        }

        .link-default {
          color: white; // Default color for all links

          &:hover {
            color: color-variables.$cyan; // Hover color for all links
          }
        }

        .link-active-yellow {
          color: color-variables.$yellow;
        }

        .link-active-green {
          color: color-variables.$green;
        }

        .link-active-red {
          color: color-variables.$red;
        }
      }
    }
  }

  .title {
    font-weight: bold;
    color: color-variables.$cyan;
    margin-bottom: 10px;
    font-size: 14px;
    border-bottom: 2px solid color-variables.$cyan;
  }
}

.content {
  & {
    flex-grow: 2;
    background-color: color-variables.$darkGrey; // Content background
    // padding: 35px 25px 0;
    padding: 3rem 2rem;
    color: white;
    z-index: 2;
    // min-width: 420px;
    // max-width: 880px;
  }

  .container {
    margin-left: 0;
  }
}

@media screen and (max-width: 1440px) {
  .sidebar {
    padding: 1rem 2rem;
  }
}
