@use "color-variables";
@use "mixins";

/******* Globals *******/
.mCenter {
  margin: auto;
}

.flex {
  display: flex;
}
.flexStart {
  justify-content: start;
}
.flexEnd {
  justify-content: end;
}
.flexSpaceBetween {
  justify-content: space-between;
}
.flexContentCenter {
  justify-content: center;
}
.flexAlignItemsCenter {
  align-items: center;
}
.flexAlignItemsTop {
  align-items: flex-start;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.flexColumn {
  flex-direction: column;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.inner {
  .hero {
    @include mixins.revolvingHero;
    min-height: 600px;
    height: 60vh;
  }
}

/******* Containers *******/
.container {
  max-width: 1020px;
  width: 90%;
  margin: auto;
  padding: 6.5rem 0;
}

.containerLg {
  max-width: 1200px;
}
.containerMd {
  max-width: 980px;
}
.containerSm {
  max-width: 720px;
}

.row {
  width: 100%;
  margin: auto;
}

.borderInfo {
  @include mixins.border-info;
}

.borderWarning {
  @include mixins.border-warning;
}

/******* BG Images *******/
.trackImageBG {
  background-image: url("../Images/tracksBG.png");
  background-size: auto;
}

/******* BG Colors *******/
.darkPurpleBG {
  background-color: color-variables.$darkPurple;
}
.magentaBG {
  background-color: color-variables.$magenta;
}
.purpleBG {
  background-color: color-variables.$purple;
}
.cyanBG {
  background-color: color-variables.$cyan;
}
.mcLarenBG {
  background-color: color-variables.$mcLaren;
}
.darkGrayBG {
  background-color: color-variables.$darkGrey;
}
.lightGrayBG {
  background-color: color-variables.$lightGrey;
}
.blackBG {
  background-color: color-variables.$black;
}

/******* Filter *******/
.magentaFilter {
  @include mixins.add-filter(color-variables.$magenta);
}
.cyanFilter {
  @include mixins.add-filter(color-variables.$cyan);
}
.yellowFilter {
  @include mixins.add-filter(color-variables.$yellow);
}
.purpleFilter {
  @include mixins.add-filter(color-variables.$purple);
}
.greenFilter {
  @include mixins.add-filter(color-variables.$green);
}

.cyanFilter2 {
  filter: drop-shadow(color-variables.$cyan 0px 0px 8px);
}
.yellowFilter2 {
  filter: drop-shadow(color-variables.$mcLaren 0px 0px 8px);
}
.magentaFilter2 {
  filter: drop-shadow(color-variables.$magenta 0px 0px 8px);
}

/******* Text Colors *******/
.wh {
  color: color-variables.$white;
}
.magenta {
  color: color-variables.$magenta;
}
.cyan {
  color: color-variables.$cyan;
}
.yellow {
  color: color-variables.$yellow;
}
.purple {
  color: color-variables.$purple;
}
.green {
  color: color-variables.$green;
}
.red {
  color: color-variables.$red;
}
.mclaren {
  color: color-variables.$mcLaren;
}
.dark {
  color: color-variables.$darkPurple;
}
.success {
  color: color-variables.$success;
}

.noTs {
  text-shadow: none;
}
.whTs {
  @include mixins.add-text-shadow(color-variables.$white);
}
.cyanTs {
  @include mixins.add-text-shadow(color-variables.$cyan);
}
.yellowTs {
  @include mixins.add-text-shadow(color-variables.$yellow);
}
.purpleTs {
  @include mixins.add-text-shadow(color-variables.$purple);
}
.greenTs {
  @include mixins.add-text-shadow(color-variables.$green);
}
.redTs {
  @include mixins.add-text-shadow(color-variables.$red);
}
.magentaTs {
  @include mixins.add-text-shadow(color-variables.$magenta);
}
.mclarenTs {
  @include mixins.add-text-shadow(color-variables.$mcLaren);
}
.darkTs {
  @include mixins.add-text-shadow(color-variables.$darkPurple);
}

/******* Text Transform *******/
.lt {
  text-align: left;
}
.rt {
  text-align: right;
}
.ct {
  text-align: center;
}
.upp {
  text-transform: uppercase;
}
.sc {
  font-variant-caps: small-caps;
}

/******* Text Sizes *******/
.xxlText {
  font-size: 6rem;
  line-height: 8.4rem;
}

.xlText {
  font-size: 4rem;
  line-height: 5.6rem;
}

.lgText {
  font-size: 3rem;
  line-height: 4.2rem;
}

.mdText {
  font-size: 1.5rem;
  line-height: 2.1rem;
}

.smdText {
  font-size: 1.2rem;
  line-height: 1.68rem;
}

.smText {
  font-size: 0.8rem;
  line-height: 1.12rem;
}

.xsText {
  font-size: 0.7rem;
  line-height: 0.98rem;
}

/******* Text Weight *******/
.bold {
  font-weight: 700;
}
.med {
  font-weight: 500;
}
.norm {
  font-weight: 400;
}
.thin {
  font-weight: 100;
}

@media screen and (max-width: 767px) {
  .xxlText {
    font-size: 4rem;
    line-height: 5.6rem;
  }

  .xlText {
    font-size: 3rem;
    line-height: 4.2rem;
  }

  .lgText {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .mdText {
    font-size: 1.2rem;
    line-height: 1.68rem;
  }

  .smdText {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
