footer {
  width: 100%;
  padding: 2rem 0 2rem;
  margin-top: auto;
  z-index: 20;

  .contain {
    margin-bottom: 1rem;
  }

  .mainLogoContainer {
    margin: 0;
  }

  .dzp {
    width: 100px;
    height: auto;
  }

  .logo {
    transition: filter 0.3s ease;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      filter: drop-shadow(1px 1px 6px #fff);
    }

    img {
      width: 35px;
      height: 30px;
    }
  }

  .logo2 {
    transition: filter 0.3s ease;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      filter: drop-shadow(1px 1px 6px #fff);
    }

    img {
      width: 30px;
      height: 25px;
    }
  }

  .copy {
    margin-bottom: 0;
    font-variant-caps: small-caps;
  }

  @media screen and (max-width: 767px) {
    .contain {
      flex-direction: column;

      .mainLogoContainer,
      .right {
        width: 90%;
        padding: 0;
        margin: auto;
      }

      .right {
        flex-direction: column;
        align-items: center;

        > div:nth-child(n + 2) {
          margin-top: 1rem;
        }
      }

      .mainLogoContainer {
        margin-bottom: 2rem;

        > div {
          padding: 0;
          justify-content: center;

          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 767px) {
    .defaultText {
      display: block; // show the default text
    }

    .smallScreenText {
      display: none; // hide the small screen text
    }
  }

  @media screen and (max-width: 767px) {
    .defaultText {
      display: none; // hide the default text
    }

    .smallScreenText {
      display: block; // show the small screen text
    }
  }
}
