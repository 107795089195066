#root .mainback {
  min-height: auto;
  padding: 8rem 0 4rem;
}

#root .mainback button.btn-warning {
  background-color: #ffc107;
  text-shadow: none;
  box-shadow: none;
  transition: background-color 0.3s ease;
}

#root .mainback button {
  background-color: #00afff;
  text-shadow: none;
  box-shadow: none;
  transition: background-color 0.3s ease;
}


.card {
  border: none;
}

.supportContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.card .card-header {
  background: none;
  border-bottom: solid 1px #fff;
}

.card .card-header.noBorder {
  border: none;
}

#root .supportResources {
  padding: 2rem 0 0 0 !important;
  margin-top: 0;
}

#root .supportInformation {
  padding: 2rem 0 !important;
  margin-top: 0;
}

.logo {
  transition: filter 0.3s ease;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    filter: drop-shadow(1px 1px 4px #000);
  }
}

.hoverHighlight {
  &:hover {
    color: #32fbe2;
  }
}

@media screen and (max-width: 767px) {
  .hr-add {
    display: block; // show the default text
  }
}

@media screen and (min-width: 767px) {
  .hr-add {
    display: none; // hide the default text
  }
}
