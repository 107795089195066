@use "../../SCSS/color-variables";
@use "../../SCSS/mixins";

.hero {
  @include mixins.revolvingHero;

  .heroContent {
    background: rgba(44, 44, 44, 0.85); // Semi-transparent charcoal background
    width: 100%;
    height: 260px;
    padding: 40px;
    text-align: center;
    z-index: 2;
  }

  .heroCat {
    padding-right: 50px;
  }
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;

  &.active {
    opacity: 1;
  }
}

.placeholderBackground {
  background-image: url("../../Images/tracksBG.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: auto;
  background-position: center;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;

  &.active {
    opacity: 1;
  }
}

.lrBorder {
  border-left: solid 1px color-variables.$white;
  border-right: solid 1px color-variables.$white;
}

.s3 {
  .image {
    height: 70px;
    width: 70px;
    margin-bottom: 15px;
  }

  .image2 {
    height: 70px;
    width: 170px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 880px) {
  .s3 {
    .col3 {
      flex-direction: column;

      > div {
        margin-bottom: 2rem;
      }

      .lrBorder {
        padding: 0.667rem;
        border: none;
        border-top: solid 1px color-variables.$white;
        border-bottom: solid 1px color-variables.$white;
      }

      > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .s5 {
    .row2 {
      flex-direction: column;

      > div {
        padding: 0.667rem;
      }
    }
  }
}
