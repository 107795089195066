@use "../../SCSS/color-variables";

nav .contain {
  width: 90%;
}

.brand-text {
  @media (min-width: 768px) and (max-width: 999px) {
    display: none;
  }
}

.nav-item.dropdown {
  margin-right: 15px;
}
.nav-item {
  padding-right: 1rem;
  color: #fff;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: color-variables.$cyan;
  }

  .nav-link {
    color: #fff;
    transition: color 0.3s ease;

    &:hover {
      color: color-variables.$cyan;
    }
  }
}

// Styles for the custom dropdown
.custom-dropdown {
  .nav-link {
    font-weight: bold;
    color: color-variables.$yellow; // Replace #yourCustomColor with your preferred color
    transition: color 0.3s ease;

    &:hover {
      color: color-variables.$cyan; // Replace #yourCustomHoverColor with your preferred hover color
    }
  }
}
