@use "../../../SCSS/color-variables";

h2 {
  margin-bottom: 15px;
  font-size: 26px;
  border-bottom: 2px solid color-variables.$magenta;
}

p {
  font-size: 14px;
}

b {
  font-size: 14px;
}

.important {
  font-size: 16px;
  color: color-variables.$red;
  text-shadow: 0 0;
}

h5 {
  font-weight: bold;
  text-shadow: 0 0;
  margin-bottom: 12px;
  font-size: 18px;
}

h6 {
  text-shadow: 0 0;
  margin: 25px 0 12px;
  font-size: 16px;
}

.spacing {
  padding-bottom: 240px;
}

ul {
  font-size: 14px;
  list-style-type: circle;
  padding: 10;

  li {
    margin-bottom: 15px;
  }
}

.accordion-button {
  background-color: color-variables.$darkGrey !important;
  color: color-variables.$white !important;
}

.accordion-button:not(.collapsed) {
  background-color: color-variables.$black !important;
  color: color-variables.$white !important;
}

.accordion-body {
  color: color-variables.$white !important;
}

.accordion-header {
  border: none !important;
  box-shadow: none !important;
  border: 0 !important; // Remove the border width
}

.faq-accordion .accordion-button:hover {
  background-color: color-variables.$black !important;
  color: color-variables.$white !important;
}
