@use "color-variables";

@mixin add-text-shadow($color) {
  text-shadow: 0 1px rgba($color, 0.6), 0 0 3px rgba($color, 0.5),
    0 0 0.5rem rgba($color, 0.3), 0 0 2rem rgba($color, 0.2);
}

@mixin add-filter($color) {
  filter: drop-shadow($color 3px 3px 3px);
}

@mixin border-info {
  background-color: rgba(color-variables.$cyan, 0.3);
  border-color: color-variables.$cyan;
  box-shadow: 0 0 2px rgba(color-variables.$cyan, 0.9),
    0 0 4px rgba(color-variables.$cyan, 0.4),
    0 0 1rem rgba(color-variables.$cyan, 0.3),
    0 0 4rem rgba(color-variables.$cyan, 0.1);
}

@mixin border-warning {
  background-color: rgba(color-variables.$mcLaren, 0.3);
  border-color: color-variables.$mcLaren;
  box-shadow: 0 0 2px rgba(color-variables.$mcLaren, 0.9),
    0 0 4px rgba(color-variables.$mcLaren, 0.4),
    0 0 1rem rgba(color-variables.$mcLaren, 0.3),
    0 0 4rem rgba(color-variables.$mcLaren, 0.1);
}

@mixin revolvingHero {
  position: relative;
  max-width: 100%;
  width: 100vw;
  min-height: 700px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

@mixin hero($imageUrl) {
  position: relative;
  max-width: 100%;
  width: 100vw;
  min-height: 700px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url($imageUrl) no-repeat bottom center/cover;
}
