/************************
          DZP           
************************/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

/*
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("/fonts/Montserrat.ttf") format("truetype");
}
 This is a Variable Font
   Typical values for font-weight are 100, 200, 300, 400, 500, 600, 700, 800, and 900,
   where 400 is usually the normal weight and 700 is bold. You can choose any value between these,
   for example, 450 or 650, to get an intermediate weight.
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 25.2px;
  text-align: left;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body,
#root {
  background: #4c4c4c;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
.navbar-brand {
  font-variant-caps: small-caps;
}

.navbar {
  box-shadow: none !important;
}

@media screen and (min-width: 768px) {
  .navbar,
  footer {
    > .container {
      width: 100%;
      max-width: none;
      padding: 0 4rem;
    }
  }
}

@media screen and (max-width: 1440px) {
  .navbar,
  footer {
    > .container {
      padding: 0 2rem;
    }
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }
  .navbar-nav {
    .dropdown-menu {
      background-color: #6f42c1;
    }
    a {
      color: white;
    }
    .dropdown-divider {
      background-color: #d4bcff;
      box-shadow: none;
    }
  }
  .sidebar {
    display: none;
  }
}
